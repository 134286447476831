const DocMenuConfig = [
  {
    pages: [
      {
        heading: 'dashboard.dashboard',
        route: '/dashboard',
        svgIcon: 'media/icons/duotune/art/art002.svg',
        fontIcon: 'bi-app-indicator',
      },
      {
        heading: 'dashboard.actualites',
        route: '/actualites',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
      },
      {
        heading: 'course.myCourses',
        route: '/course',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
      },
      {
        heading: 'course.myExercises',
        route: '/exercise',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
      },
      {
        heading: 'student.myStudents',
        route: '/students',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
      },
      {
        heading: 'course.researches',
        route: '/teacher/research',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
      },
      {
        heading: 'course.exercisesValidation',
        route: '/exercisesValidation',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'advisor',
      },
      {
        heading: 'course.coursesValidation',
        route: '/coursesValidation',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'advisor',
      },
      {
        heading: 'course.myResearches',
        route: '/research',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'advisor',
      },
      {
        heading: 'teacher.myTeachers',
        route: '/teachers',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'advisor',
      },
      {
        heading: 'meeting.myMeetings',
        route: '/meetings',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'advisor',
      },
      {
        heading: 'meeting.meetings',
        route: '/teacher/meetings',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
      },
      {
        heading: 'availability.title',
        route: '/teacher/availability',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
        access: 'teacherAvailability',
      },
      {
        heading: 'punishments.punishments',
        route: '/punishments',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'advisor',
      },
      {
        heading: 'Sghartoon',
        route: '/sghartoon',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        access: 'sghartoon',
      },
      {
        heading: 'library.library',
        route: '/library',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        fontIcon: 'bi-layers',
        role: 'teacher',
      },
    ],
  },
]

export default DocMenuConfig
